<template>
	<section class="error-page">
		<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<strong class="h1">
						404
					</strong>
					<p v-if="error.statusCode === 404">
						... page not found
					</p>
					<p v-else>
						... oops, an error has occurred
					</p>
					<nuxt-link to="/" class="button button--primary">
						Back to home
					</nuxt-link>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'error',
		head: {
			title: 'Page not found - habitable.co',
		},
		props: ['error'],
		layout: 'error' // you can set a custom layout for the error page
	}
</script>
